<template>
  <main>
    <table class="table table-striped table-sm table-bordered font-size-12">
      <tr ><th colspan="2">Factores de riesgo modificables</th></tr>
      <tr>
        <th>Factores</th>
        <th>Respuesta</th>
      </tr>
      <tr v-for="item in riesgosAgrupados.modificable" :key="item.id">
        <td>{{ item.riesgo.nombre }}</td>
        <td>
         {{item.respuesta}}
        </td>
      </tr>
    </table>
    <table class="table table-striped table-sm table-bordered font-size-12">
      <tr ><th colspan="2">Factores de riesgo No modificables</th></tr>
      <tr>
        <th>Factores</th>
        <th>Respuesta</th>
      </tr>
      <tr v-for="item in riesgosAgrupados.no_modificable" :key="item.id">
        <td>{{ item.riesgo.nombre }}</td>
        <td>
         {{item.respuesta}}
        </td>
      </tr>
    </table> 
  </main>
</template>
<script>

export default {
  props: ["idHistoria",'data'],
  data() {
    return {
      riesgosAgrupados: {} 
    };
  },
  created() {
    this.agruparRiesgosPorTipo();
  },
  watch:{
    data:function(){
      this.agruparRiesgosPorTipo();
    }
  },
  methods: {
    agruparRiesgosPorTipo() {
      this.data.forEach((riesgo) => {
        if (!this.riesgosAgrupados[riesgo.riesgo.tipo]) {
          this.riesgosAgrupados[riesgo.riesgo.tipo] = [];
        }
        this.riesgosAgrupados[riesgo.riesgo.tipo].push(riesgo);
      });
    }
  }
};
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>