<template>
    <main>
        <div class="card shadow-none mt-2">
        <div class="card-body">
            <div class="row mt-2">
            <div class="form-group col-lg-4 col-md-12 col-sm-12">
                <label class="font-weight-bolder">Género</label>
                <select class="form-control" v-model="genero" :class="{'is-invalid':$v.genero.$error && $v.genero.$dirty}" readonly>
                    <option value="F">F</option>
                    <option value="M">M</option>
                </select>
            </div>
            <div class="form-group col-lg-4 col-md-12 col-sm-12">
                <label class="font-weight-bolder">Edad</label>
                <input type="number" class="form-control" v-model="edad_u" readonly>
            </div>
            <div class="form-group col-lg-4 col-md-12 col-sm-12">
                <label class="font-weight-bolder">IMC</label>
                <input type="number" class="form-control" v-model="indice_masa_corporal" :class="{'is-invalid':$v.indice_masa_corporal.$error && $v.indice_masa_corporal.$dirty}" readonly>
            </div>
            <div class="form-group col-lg-4 col-md-12 col-sm-12">
                <label class="font-weight-bolder">Usa medicamentos para la presión</label>
                <select class="form-control" v-model="medicamentos_presion" :class="{'is-invalid':$v.medicamentos_presion.$error && $v.medicamentos_presion.$dirty}">
                    <option value="y">SI</option>
                    <option value="n">NO</option>
                </select>
            </div>
            <div class="form-group col-lg-4 col-md-12 col-sm-12">
                <label class="font-weight-bolder">Antecedentes personales de diabetes</label>
                <select class="form-control" v-model="historial_glucosa" :class="{'is-invalid':$v.historial_glucosa.$error && $v.historial_glucosa.$dirty}">
                    <option value="y">SI</option>
                    <option value="n">NO</option>
                </select>
            </div>
            <div class="form-group col-lg-4 col-md-12 col-sm-12">
                <label class="font-weight-bolder">Actividad fisica en horas a la semana</label>
                <input type="number" class="form-control" v-model="actividad_fisica" :class="{'is-invalid':$v.actividad_fisica.$error && $v.actividad_fisica.$dirty}">
            </div>
            <div class="form-group col-lg-4 col-md-12 col-sm-12">
                <label class="font-weight-bolder">Consumo diario de frutas</label>
                <select class="form-control" v-model="consumo_diario_frutas" :class="{'is-invalid':$v.consumo_diario_frutas.$error && $v.consumo_diario_frutas.$dirty}">
                    <option value="y">SI</option>
                    <option value="n">NO</option>
                </select>
            </div>
            <div class="form-group col-lg-4 col-md-12 col-sm-12">
                <label class="font-weight-bolder">Antecedentes familiares de diabetes</label>
                <select class="form-control" v-model="historial_diabetes" :class="{'is-invalid':$v.historial_diabetes.$error && $v.historial_diabetes.$dirty}">
                    <option value="n">No</option>
                    <option value="1g">1 Grado</option>
                    <option value="2g">2 Grado</option>
                </select>
            </div>
            <div class="form-group col-lg-4 col-md-12 col-sm-12">
                <label class="font-weight-bolder">Perimetro abdominal</label>
                <input type="number" step="any" class="form-control" v-model="perimetro_abdominal" :class="{'is-invalid':$v.perimetro_abdominal.$error && $v.perimetro_abdominal.$dirty}">
            </div>
            </div>
        </div>
        <div class="card-footer">
            <div class="list-group mt-2 col-lg-8 col-md-12 col-sm-12">
                <div class="list-group-item">
                    <span class="font-weight-bolder badge badge-light badge-marketing mx-2">Puntaje : {{this.puntaje}}</span>
                    <span :class="class_risk" class="font-weight-bolder">Riesgo: {{ this.riesgo }}</span>
                </div>
           </div>
        </div>
    </div>
    <div class="mt-2">
        <button class="btn btn-primary mx-2 btn-sm" @click="calcular();"><i class="fa fa-calculator fa-fw"></i>Calcular</button>
        <button class="btn btn-success btn-sm" @click="guardar();"><i class="fa fa-save fa-fw"></i>Guardar</button>
    </div>
    </main>
</template>
<script>
import examenFisicoService from '../../../../services/examenFisicoService';
import findriscService from '../../../../services/findriscService';
import { findrisc } from '../../../../utils/utils';
import { isEmpty } from "lodash";
import Toast from '../../../../components/common/utilities/toast';
import dayjs from 'dayjs';
import { required } from 'vuelidate/lib/validators';
import Swal from 'sweetalert2';


export default {
    props: ['idHistoria', 'finalizada', 'idUsuario', 'sexo', 'fecha_nacimiento'],
    data() {
        return {
            genero: '',
            imc: '',
            medicamentos_presion: '',
            historial_glucosa: '',
            actividad_fisica: '',
            consumo_diario_frutas: '',
            historial_diabetes: '',
            puntaje: '',
            riesgo: '',
            perimetro_abdominal: '',
            peso: '',
            talla: '',
            id_findrisc: '',
            class_risk:''

        }
    },
    validations(){
        return {
            genero:{required},
            medicamentos_presion:{required},
            historial_glucosa:{required},
            actividad_fisica:{required},
            consumo_diario_frutas:{required},
            historial_diabetes:{required},
            perimetro_abdominal:{required},
            peso:{required},
            indice_masa_corporal:{required},
        }
    },
    methods: {
        async calcular() {
            try {

                this.$v.$touch();
                if(this.$v.$invalid) return ;

                const gender = this.genero === 'F' ? 'female' : 'male';

                const riesgo = findrisc(gender,
                    this.edad_u,
                    this.indice_masa_corporal,
                    this.perimetro_abdominal,
                    this.medicamentos_presion,
                    this.historial_glucosa,
                    this.actividad_fisica,
                    this.consumo_diario_frutas,
                    this.historial_diabetes
                )

                this.riesgo = riesgo['risk'];
                this.puntaje = riesgo['points'];
                this.class_risk=riesgo['class'];

            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        async guardar() {
            try {

                if(this.puntaje===''){
                    Swal.fire(
                        'Error',
                        'Debe calcular el riesgo antes de guardar',
                        'error'
                    )
                    return;
                }

                let findrisc_h = {
                    id_historia: this.idHistoria,
                    genero: this.genero,
                    edad: this.edad_u,
                    imc: this.indice_masa_corporal,
                    perimetro_abdominal: this.perimetro_abdominal,
                    medicamentos_presion: this.medicamentos_presion,
                    historial_glucosa: this.historial_glucosa,
                    actividad_fisica: this.actividad_fisica,
                    consumo_diario_frutas: this.consumo_diario_frutas,
                    historial_diabetes: this.historial_diabetes,
                    riesgo: this.riesgo,
                    puntaje: this.puntaje,
                }

                this.LoaderSpinnerShow();

                if (this.id_findrisc !== '') {
                    findrisc_h['id'] = this.id_findrisc;
                    await findriscService.update(findrisc_h);
                } else {
                    await findriscService.store(findrisc_h);
                    const response = await findriscService.showByHc(this.idHistoria);
                    this.id_findrisc = response.data.id;
                }


                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });

            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        }
    },
    async created() {
        this.genero = this.sexo;
        //this.findrisc.edad=this.edad;
        if (this.finalizada === 'N') {
            const response = await examenFisicoService.showByHc(this.idHistoria);
            if (!isEmpty(response.data)) {
                this.talla = response.data.talla;
                this.peso = response.data.peso;
                this.perimetro_abdominal = Number(response.data.perimetro_abdominal);
            }

            const response_findrisc = await findriscService.showByHc(this.idHistoria);
            if (!isEmpty(response_findrisc.data)) {
                //this.edad_u=response_findrisc.data.edad;
                this.medicamentos_presion = response_findrisc.data.medicamentos_presion;
                this.historial_glucosa = response_findrisc.data.historial_glucosa;
                this.actividad_fisica = response_findrisc.data.actividad_fisica;
                this.consumo_diario_frutas = response_findrisc.data.consumo_diario_frutas;
                this.historial_diabetes = response_findrisc.data.historial_diabetes;
                this.perimetro_abdominal = response_findrisc.data.perimetro_abdominal;
                this.puntaje = response_findrisc.data.puntaje;
                this.riesgo = response_findrisc.data.riesgo;
                this.id_findrisc = response_findrisc.data.id;
            }
        }
    },
    watch: {
        finalizada: async function (value) {
            if (value === 'N') {
                const response = await examenFisicoService.showByHc(this.idHistoria);
                if (!isEmpty(response.data)) {
                    this.talla = response.data.talla;
                    this.peso = response.data.peso;
                    this.perimetro_abdominal = Number(response.data.perimetro_abdominal);
                }
            }
        }
    },
    computed: {
        indice_masa_corporal() {

            if (this.peso !== '' && this.talla !== '') {
                let imc = Number(this.peso) / (Math.pow(Number(this.talla / 100), 2));
                return imc.toFixed(2);
            }

            return '';
        },
        edad_u: function () {
            if (isEmpty(this.fecha_nacimiento)) return 0;
            return dayjs().diff(this.fecha_nacimiento, 'year');
        },
    }
}
</script>