<template>
    <main>
       <div class="">
           <div class="card-header bg-light">Tanner</div>
           <div class="card-body">
            <div class="row" v-for="item in data" :key="item.id">
                <div class="col-12">
                <h6>{{item.estadio.tipo.nombre}}</h6>
               <table class="table table-bordered table-sm font-size-12">
                   <tr>
                       <th>Tanner</th>
                       <th>Descripcion</th>
                    </tr>
                   <tr>
                       <td class="text-center align-middle">{{item.estadio.estadio}}</td>
                       <td class="align-middle">{{item.estadio.descripcion}}</td>
                   </tr>
               </table>
                </div>
               </div>
            </div>
        </div>
    </main>
</template>
<script>  
export default {
    props: ['idHistoria','finalizada', 'sexo','data'],
}
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>